import ServiceDashboard from "@/api/serviceDashboard"

const state = () => ({
    user: {
        api_key: '',
        balance: null,
        ban_reason: "",
        campaign_limit: false,
        email: "",
        enable_ar: null,
        g2a: 1,
        id: null,
        invoice_data: "",
        invoice_name: "",
        manager: [],
        name: "",
        news: 0,
        notice: 0,
        notice_limit: 12312312,
        on_page: 20,
        ref_code: "",
        rid: 0,
        roles: ["ROLE_USER"],
        skype: "",
        telegram: "",
        telegram_id: null,
        tickets: 0
    },
    userPid: '',
    referralId: ''
})

const getters = {
    getUser: (state) => {
        return state.user
    },
    getUserPid: (state) => {
        return state.userPid
    },
    getReferralId: (state) => {
        return state.referralId
    }
}

const mutations = {
    setUserStore(state, payload) {
        if (payload) {
            state.user = payload
        }
    },
    setUserStorePid(state, payload) {
        if (payload) {
            state.userPid = payload
        }
    },
    setUserReferralId(state, payload) {
        if (payload) {
            state.referralId = payload
        }
    }
}

const actions = {
    setUser({commit}) {
        ServiceDashboard.getUser().then((result) => {
            commit('setUserStore', result)
        });
    },
    setUserPid({commit}, payload) {
        commit('setUserStorePid', payload)
    },
    setUserReferral({commit}, payload) {
        commit('setUserReferralId', payload)
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}