<template>
  <div class="full-height">
    <PanelHeader @openFormModal="openFormModal" @toggleMenu="toggleMenu" />
    <div class="wrapper100 contentBlock">
      <div class="container-fluid">
        <div class="row flex-nowrap">
          <MenuWrapper
            :isOPen="isOPen"
            :close="close"
            @openFormModal="openFormModal"
          />
          <div class="col content-wrapper">
            <div class="inner-content">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :width="550"
      height="auto"
      name="rateModal"
      :scrollable="true"
      classes="un-overflow rounded-large"
    >
      <RateModal :manager="user.manager" @closeModal="closeModal" />
    </modal>
    <notifications group="error" />
  </div>
</template>


<script>
import apiClient from "@/services/AxiosService";
import "@/assets/panel/css/main.scss";
import MenuWrapper from "@/components/MenuWrapper";
import PanelHeader from "@/components/PanelHeader";
import { mapActions, mapGetters } from "vuex";
import Chatra from "@chatra/chatra";
import * as Sentry from "@sentry/vue";
import RateModal from "@/components/modals/rateModal.vue";

export default {
  name: "PanelLayout",
  components: { RateModal, PanelHeader, MenuWrapper },
  data() {
    return {
      isOPen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      selectCountries: "countries/getCountries",
    }),
    config() {
      return {
        ID: "wSGkdGKmz269FkaGF",
        integration: {
          name: `${this.user.name}`,
          email: `${this.user.email}`,
        },
      };
    },
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && val.id) {
          Chatra("init", this.config);
          Sentry.setUser({ id: val.id, email: val.email });
        }
      },
    },
  },
  created() {
    this.setUser();
    !this.selectCountries.length && this.setCountries();
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      setCountries: "countries/handleCountries",
    }),
    closeModal($event) {
      if ($event && typeof $event == "boolean") {
        this.$notify({
          group: "error",
          title: this.$t("sent_success"),
          text: this.$t("your_rating_important"),
          ignoreDuplicates: true,
          type: "success",
        });
      } else if ($event) {
        this.$notify({
          group: "error",
          title: this.$t("sent_error"),
          text: $event,
          ignoreDuplicates: true,
          type: "error",
        });
      }
      this.$modal.hide("rateModal");
    },
    openFormModal() {
      this.whichModerator = this.user.manager;
      this.$modal.show("rateModal");
    },

    close() {
      if (!this.isOPen) return;
      this.isOPen = false;
    },
    toggleMenu() {
      this.isOPen = !this.isOPen;
    },
    changeLanguage() {
      const newLang = this.$i18n.locale === "ru" ? "en" : "ru";
      this.$i18n.locale = newLang;
      localStorage.setItem("lang", newLang);
    },
    logOut() {
      localStorage.setItem("authToken", "");
      localStorage.setItem("refreshToken", "");
      apiClient.get("user/logout");
      this.$router.push("/");
    },
  },
};
</script>
<style>
.full-height {
  height: 100%;
}
</style>

