<template>
  <div>
    <button class="language-image btn btn-empty" @click="changeLanguage">
      <img
          width="30"
          height="20"
          :alt="$i18n.locale+' language'"
          :src="require('@/assets/landing/images/lang/'+srcLocale+'.svg')"
      />
    </button>
  </div>

</template>
<script>

export default {
  name: 'LanguagesButton',
  created() {
    this.getLocale()
  },
  methods: {
    getLocale(){
      const savedLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
      if (savedLang) {
        this.$i18n.locale = savedLang;
      } else {
        this.$i18n.locale = (navigator.language || navigator.userLanguage)  === 'ru' ? 'ru' : 'en'
      }
    },
    changeLanguage() {
      const newLang = this.$i18n.locale === 'ru' ? 'en' : 'ru'
      this.$i18n.locale = newLang
      localStorage.setItem("lang", newLang)
      this.$setLangHeader(newLang)
    }
  },
  computed:{
    srcLocale(){
      return this.$i18n.locale === 'ru' ? 'ru' : 'en'
    }
  }
}
</script>