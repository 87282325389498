<template>
  <div class="wrapper inner-page">
    <div class="inner">
      <header>
        <LandingHeader></LandingHeader>
      </header>
        <slot/>
      <MainFooter/>
    </div>
  
  </div>
</template>

<script>
import LandingHeader from "@/components/LandingHeader"
import MainFooter from "@/components/MainFooter"
import "@/assets/css/terms.sass"
export default {
  name: 'PageLayout',
  components: {MainFooter, LandingHeader},
}
</script>