<template>
  <div class="wrapper100 header-block">
    <div class="container-fluid">
      <div class="row">
        <div class="logo-wrapper">
          <div class="header-logo-block">
            <a href="/"><img class="img-fluid" src="@/assets/panel/images/symbol/logo.svg" alt="logo"/></a>
          </div>
        </div>
        <div class="header-row">
          <div class="header-manager header-manager--manager">
            <div class="header-manager--round">
              <div class="header-manager--image">
                <img src="@/assets/panel/images/new/Maneger_icon.svg" alt="manager">
              </div>
              <div class="header-manager--icon">
                <svg class="svg-sprite-icon svg-sprite-unstyled icon-telegram">
                  <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#telegram"></use>
                </svg>
              </div>
            </div>
            <div class="header-manager--info">
              <div class="header-manager--info-key">{{ $t("Manager") }}:</div>
              <button @click="$emit('openFormModal' )" class="header-manager--info-val link"
                 >{{ user.manager.name }}</button>
            </div>
          </div>
          <div class="header-manager header-manager--moderator">
            <div class="header-manager--round">
              <div class="header-manager--image">
                <img src="@/assets/panel/images/new/Moderator_icon.svg" alt="moderator">
              </div>
              <div class="header-manager--icon">
                <svg class="svg-sprite-icon svg-sprite-unstyled icon-telegram">
                  <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#telegram"></use>
                </svg>
              </div>
            </div>
            <div class="header-manager--info">
              <div class="header-manager--info-key">{{ $t("Moderator") }}:</div>
              <a href="https://t.me/KatrinSF" class="header-manager--info-val" target="_blank">@KatrinSF</a>
            </div>
          </div>
          <div class="header-row--right">
            <div v-click-outside="hide" class="header-user" :class="{'active':opened}">
              <div class="header-user--visible" @click="toggle">
                <div class="header-user--icon">
                  <svg class="svg-sprite-icon svg-sprite-unstyled icon-user">
                    <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#user"></use>
                  </svg>
                </div>
                <div class="header-user--username">{{ user.name }}</div>
                <div class="header-user--dropdown">
                  <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-arrow">
                    <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-arrow"></use>
                  </svg>
                </div>
              </div>
              <div class="header-user--menu" v-if="opened">
                <router-link @click.native="hide" to="/panel/profile" class="header-user--menu-link">
                                            <span class="header-user--menu-icon">
                                                <svg class="svg-sprite-icon svg-sprite-unstyled icon-user2">
                                                <use
                                                    xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#user2"></use>
                                                </svg>
                                            </span>
                  <span class="header-user--menu-text">
                                                {{ $t("profile") }}
                                            </span>
                </router-link>
                <router-link @click.native="hide" to="/panel/notices" class="header-user--menu-link">
                                            <span class="header-user--menu-icon" :class="{ active: user.notice>0 }">
                                                <svg class="svg-sprite-icon svg-sprite-unstyled icon-notify">
                                                <use
                                                    xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#notify"></use>
                                                </svg>
                                            </span>
                  <span class="header-user--menu-text">
                                                {{ $t("Notices") }}
                                            </span>
                </router-link>
                <router-link @click.native="hide" to="/panel/referrals" class="header-user--menu-link">
                                            <span class="header-user--menu-icon">
                                                <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-ref">
                                                <use
                                                    xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-ref"></use>
                                                </svg>
                                            </span>
                  <span class="header-user--menu-text">
                                                {{ $t("Referrals") }}
                                            </span>
                </router-link>
                <router-link @click.native="hide" to="/panel/api" class="header-user--menu-link">
                                            <span class="header-user--menu-icon">
                                                <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-api">
                                                <use
                                                    xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-api"></use>
                                                </svg>
                                            </span>
                  <span class="header-user--menu-text">
                                                API
                                            </span>
                </router-link>
                <a href="#" class="header-user--menu-link" @click="logOut">
                                            <span class="header-user--menu-icon">
                                                <svg class="svg-sprite-icon svg-sprite-unstyled icon-logout2">
                                                <use
                                                    xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#logout2"></use>
                                                </svg>
                                            </span>
                  <span class="header-user--menu-text">
                                                {{ $t("Exit") }}
                                            </span>
                </a>
              </div>
            </div>
            <router-link to="/panel/finances" class="header-finance">
              <div class="header-finance--icon">
                <svg class="svg-sprite-icon svg-sprite-unstyled icon-dollar">
                  <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#dollar"></use>
                </svg>
              </div>
              <div class="header-finance--value">{{ userBalance }}</div>
            </router-link>
            <LanguagesButton class="header-lang"/>
            <div class="header-burger" @click="$emit('toggleMenu')">
              <svg class="svg-sprite-icon svg-sprite-unstyled icon-burger">
                <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#burger"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import apiClient from "@/services/AxiosService";
import {mapGetters} from "vuex";
import LanguagesButton from "@/components/LanguagesButton";
import ClickOutside from 'vue-click-outside'

export default {
  name: 'PanelHeader',
  components: { LanguagesButton},
  data() {
    return {
      opened: false,
      balance: 0,
      openModal: false,
      whichModerator: ''
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser"
    }),
    userBalance() {
      return (this.user?.balance % 1) === 0 ? this.user.balance?.toFixed(0) : this.user.balance?.toFixed(2);
    }
  },
  methods: {
    toggle() {
      this.opened = !this.opened
    },
    hide() {
      this.opened = false
    },
    logOut() {
      apiClient.get("/v1/users/logout")
      localStorage.setItem("authToken", '')
      localStorage.setItem("refreshToken", '')
      this.$router.push('/')
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
<style lang="scss" >
.rounded-large.vm--modal.vm--modal {
  border-radius: 20px;
  max-height: 96vh;
  overflow: auto;
  background: none;
   
  .modal-content{
    border-radius: 20px;
  }
}

.link{
  border: 0;
  outline: none;
  box-shadow: none;
  display: inline;
  background: none;
}
</style>