<template>
  <div class="menu-wrapper" :class="{'active': isOPen}">
    <div class="menu-block">
      <div class="mobile-close" @click="close">
        <svg class="svg-sprite-icon svg-sprite-unstyled icon-close">
          <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#close"></use>
        </svg>
      </div>
      <div class="mobile-manager-row">
        <div class="header-manager header-manager--manager">
          <div class="header-manager--round">
            <div class="header-manager--image">
              <img src="@/assets/panel/images/new/Maneger_icon.svg" alt="manager">
            </div>
            <div class="header-manager--icon">
              <svg class="svg-sprite-icon svg-sprite-unstyled icon-telegram">
                <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#telegram"></use>
              </svg>
            </div>
          </div>
          <div class="header-manager--info">
            <div class="header-manager--info-key">{{ $t("Manager") }}:</div>
            <button @click="$emit('openFormModal' )" class="header-manager--info-val link"
            >{{ user.manager.name }}</button>
          </div>
        </div>
        <div class="header-manager header-manager--moderator">
          <div class="header-manager--round">
            <div class="header-manager--image">
              <img src="@/assets/panel/images/new/Moderator_icon.svg" alt="moderator">
            </div>
            <div class="header-manager--icon">
              <svg class="svg-sprite-icon svg-sprite-unstyled icon-telegram">
                <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#telegram"></use>
              </svg>
            </div>
          </div>
          <div class="header-manager--info">
            <div class="header-manager--info-key">{{ $t("Moderator") }}:</div>
            <a href="https://t.me/KatrinSF" class="header-manager--info-val" target="_blank">@KatrinSF</a>
          </div>
        </div>
      </div>
      <ul class="left-main-menu">
        <li>
          <router-link @click.native="close" to="/panel/dashboard" active-class="active">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-main">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-main"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">{{ $t("menu.main_panel") }}</span>
          </router-link>
        </li>
        <li>
          <router-link  @click.native="close" active-class="active" to="/panel/news">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-news">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-news"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">{{ $t("News") }}</span>
            <span class="main-menu-count" v-if="user.news">{{ user.news }}</span>
          </router-link>
        </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/panel/review">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-network-vol">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-network-vol"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    {{ $t("landing.review") }}
                                </span>

          </router-link>
        </li>

        <li class="parent" ref="toggleParent">
          <a href="javascript:void(0)" class="menu-toggle" @click="toggleOpen">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-push">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-push"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    {{$t('campaigns_title')}}
                                </span>
            <span class="left-main-menu-dropdown">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-arrow">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-arrow"></use>
                                    </svg>
                                </span>
          </a>
            <ul class="hidden-menu">
              <li>
                <router-link @click.native="close" active-class="active" to="/advertiser/create-campaign">{{ $t("menu.create_campaign") }}</router-link>
              </li>
              <li>
                <router-link @click.native="close" active-class="active" to="/advertiser/campaigns">{{ $t("menu.campaigns_list") }}</router-link>
              </li>
              <li>
                <router-link @click.native="close" active-class="active" to="/advertiser/groups">{{ $t("menu.campaign_groups") }}</router-link>
              </li>
            </ul>
        </li>
        <li>
                <router-link @click.native="close" active-class="active" to="/advertiser/statistics">
                <span class="left-main-menu-icon">
                <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-network-vol">
                    <use
                        xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#stats"></use>
                </svg>
            </span>
            <span class="left-main-menu-text">
             {{ $t("menu.statistics") }}
            </span></router-link>
              </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/advertiser/tracker">
            <span class="left-main-menu-icon">
                <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-finance">
                    <use
                        xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-tracker"></use>
                </svg>
            </span>
            <span class="left-main-menu-text">
              {{ $t("tracker.alert_title") }}
            </span>

          </router-link>
        </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/panel/finances">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-finance">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-finance"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    {{ $t("menu.finance") }}
                                </span>
          </router-link>
        </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/panel/audiences">
                                <span class="left-main-menu-icon">
                                    
                                    
      <svg  class="svg-sprite-icon svg-sprite-unstyled icon-menu-audience" width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg">
        <use  xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-audience"></use>
      </svg>

        </span>
            <span class="left-main-menu-text">
               {{ $t("menu.audiences") }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/panel/support">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-support">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-support"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    {{ $t("menu.support") }}
                                </span>
          </router-link>
        </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/panel/bonuses">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-support">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-bonuses"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    {{ $t("menu.bonuses") }}
                                </span>
          </router-link>
        </li>

        <li>
          <router-link @click.native="close" active-class="active" to="/panel/pages/rules">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-rules">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-rules"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    {{ $t("menu.rules") }}
                                </span>
          </router-link>
        </li>
        <li>
          <router-link @click.native="close" active-class="active" to="/panel/faq">
                                <span class="left-main-menu-icon">
                                    <svg class="svg-sprite-icon svg-sprite-unstyled icon-menu-faq">
                                        <use
                                            xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#menu-faq"></use>
                                    </svg>
                                </span>
            <span class="left-main-menu-text">
                                    F.A.Q.
                                </span>
          </router-link>
        </li>
      </ul>
      <div class="left-link-block">
        <a href="https://t.me/publisher_push_house" class="left-link left-link--tg" target="_blank">
                            <span class="left-link--icon">
                                <svg class="svg-sprite-icon svg-sprite-unstyled icon-telegram">
                                    <use xlink:href="@/assets/panel/images/symbol/symbol_unstyled.svg#telegram"></use>
                                </svg>
                            </span>
          <span class="left-link--text">Telegram</span>
        </a>
      </div>
      <div class="copyrightsBlock">
        <div class="copyrights">
          © Push.House 2018 - 2022 <br/>
          {{ $t('all_rights_reserved')}}<br/>
          <br/>
          <small>
            <router-link @click.native="close" to="/panel/pages/job" class="footer-link footer-link_start">{{ $t('job')}}</router-link>
            <br/>
            <router-link @click.native="close" to="/panel/pages/terms" class="footer-link">{{ $t('terms_of_use')}}</router-link>
            <br/>
            <router-link @click.native="close" to="/panel/pages/privacy" class="footer-link">{{ $t('privacy_policy')}}</router-link>
          </small>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'MenuWrapper',
  props: {
    isOPen: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: () => {},
    }
  },
  data(){
    return{
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser"
    })
  },
  methods:{
    toggleOpen(event){
      const content = event.target.closest('.parent').querySelector('.hidden-menu');
      event.target.closest('.parent').classList.toggle( 'opened')
      if (content.style.maxHeight){
        content.style.maxHeight = null
      } else {
        content.style.maxHeight = content.scrollHeight + 40 + "px"
      }
      content.classList.toggle('show')
    }
  }
}
</script>