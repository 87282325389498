import Vue from 'vue'
import store from './store'
import i18n from './i18n'
import router from "./router"
import App from "./App.vue";
import VueScrollTo from 'vue-scrollto'
import Notifications from 'vue-notification'
import DefaultLayout from "@/layouts/DefaultLayout"
import AuthLayout from "@/layouts/AuthLayout"
import PageLayout from "@/layouts/PageLayout"
import PanelLayout from "@/layouts/PanelLayout"
import ReviewLayout from "@/layouts/ReviewLayout"
import VueApexCharts from 'vue-apexcharts'
import VTooltip from 'v-tooltip'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'


import {
    faInfinity,
    faArrowCircleLeft,
    faArrowLeft,
    faArrowRight,
    faArrowCircleRight,
    faCaretDown,
    faExternalLinkAlt,
    faCaretUp,
    faTrashAlt,
    faCheck,
    faMobileAlt,
    faDesktop,
    faPlus,
    faPlay,
    faPause,
    faTrash,
    faCalendarAlt,
    faTimes,
    faEye,
    faPencilAlt,
    faUndo,
    faList,
    faExclamationCircle,
    faSearch,
    faChartBar,
    faRecycle
} from '@fortawesome/free-solid-svg-icons'
library.add(fab, faQuestionCircle)
import {faSmile, faClone, faQuestionCircle, faCheckCircle} from '@fortawesome/free-regular-svg-icons'
import Multiselect from 'vue-multiselect'
import {ValidationProvider, extend, ValidationObserver} from "vee-validate"
import {regex} from "vee-validate/dist/rules"
import {required} from 'vee-validate/dist/rules'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueFlags from "@growthbunker/vueflags"
import * as Sentry from "@sentry/vue"
import {BrowserTracing} from "@sentry/tracing";
import VueRouter from "vue-router";
import apiClientPlugin from './services/apiClientPlugin';
import Vuelidate from 'vuelidate'

Vue.use(apiClientPlugin);
Vue.use(VueRouter);
Vue.use(Vuelidate)
Sentry.init({
    Vue,
    dsn: 'https://0a7df03168374961a5a6022e6e762f0b@oops2.push.house/10',
    environment: process.env.NODE_ENV,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "push.house", /^\//],
        }),
    ],
    tracesSampleRate: process.env.CI == true ? 0.5 : 1.0,
})

Vue.use(VueFlags, {
    iconPath: '/images/flags/',
});

extend("regex", regex);
extend('required', required);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

Vue.component('multiselect', Multiselect)
Vue.component("font-awesome-icon", FontAwesomeIcon)
library.add(faCheckCircle, faInfinity, faArrowCircleLeft, faArrowLeft, faArrowRight, faArrowCircleRight, faExternalLinkAlt, faCaretDown, faCaretUp, faTrashAlt, faQuestionCircle, faSmile, faCheck, faMobileAlt, faDesktop, faPlus, faPlay, faPause, faTrash, faCalendarAlt, faTimes, faEye, faPencilAlt, faClone, faUndo, faList, faSearch, faExclamationCircle,faChartBar,faRecycle);

Vue.config.productionTip = false;
Vue.use(VModal)
Vue.use(VTooltip)
Vue.use(VueScrollTo)
Vue.use(VueApexCharts)

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('page-layout', PageLayout)
Vue.component('panel-layout', PanelLayout)
Vue.component('review-layout', ReviewLayout)
Vue.component('apexchart', VueApexCharts)


Vue.config.productionTip = true
Vue.use(Notifications)
new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        // additional fuse in case when there is no /ads.js file, and onload handler that must
        // hide this block was not called
        document.querySelector(".notice-work-conditions")?.remove();
    },
}).$mount('#app')
