<template>
  <div class="wrapper single-page">
    <div class="inner">
      <header>
        <LandingHeader></LandingHeader>
      </header>
        <slot/>
    </div>
  
  </div>
</template>

<script>
import LandingHeader from "@/components/LandingHeader"
import "@/assets/css/terms.sass"
export default {
  name: 'PageLayout',
  components: { LandingHeader},
}
</script>
<style lang="scss">
.wrapper {
  &.single-page{
    header {
      background: none;
      padding: 10px 0;
      position: relative;
    }
  }
}
</style>