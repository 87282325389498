<template>
  <footer>
    <div class="container with-padding">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-lg-3 logo-block">
          <div class="logo">
            <router-link to="/"><img alt="logo" class="img-fluid" :src="require(`@/assets/landing/images/logo.svg`)" /></router-link>
          </div>
        </div>
        <div class="col-12 col-lg-8 menu-block">
          <ul>
            <li>
              <router-link to="/pages/rules">{{ $t("landing.terms_of_use") }}</router-link>
            </li>
            <li>
              <router-link to="/pages/privacy">{{ $t("landing.privacy_policy") }}</router-link>
            </li>
            <li>
              <router-link to="/pages/prohibited-use">{{ $t("landing.prohibited_use") }}</router-link>
            </li>
            <li>
              <router-link to="/pages/job" class="vacancyLink">{{ $t("landing.job_vacancy") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-4 col-lg-1 certified-block">
          <a
              href="https://passport.webmoney.ru/asp/certview.asp?wmid=171707400610"
              target="_blank"
          >
            <img alt="wm" src="@/assets/landing/images/wm_attestat.png"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'MainFooter'
}
</script>
