import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import filter from "./modules/filter";
import countries from "./modules/countries";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    filter,
    countries,
  },
});
