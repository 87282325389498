<template>
  <div class="full-height">
      <slot/>
  </div>
</template>

<script>
import "@/assets/css/bootstrap.css"
import Chatra from '@chatra/chatra'
export default {
  name: 'DefaultLayout',
  computed: {
    config(){
      return {
        ID: 'wSGkdGKmz269FkaGF'
      }
    }
  },
  created() {
    Chatra('init', this.config)
  }
}
</script>