<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-2">
        <div class="logo">
          <router-link to="/"><img alt="logo" class="img-fluid" :src="require(`@/assets/landing/images/logo.svg`)" /></router-link>
        </div>
      </div>
      <div
          class="
                main-top-menu-Bl
                col-12 col-xl-9
                d-flex
                flex-wrap flex-md-nowrap
                justify-content-md-end
                align-items-md-center
              ">

        <div class="contact-buttons-block text-md-right">
          <a href="https://t.me/PH_support2" class="btn btn-transparent" target="_blank">PH_Support2</a>
          <a href="https://t.me/PH_aleks2" class="btn btn-transparent" target="_blank">PH_aleks2</a>
          <a
              href="skype:push.house.partner?chat"
              target="_blank"
              class="btn btn-skype btn-transparent"
          >Push.House</a>
        </div>

        <div class="buttons-block d-flex">
          <router-link to="/auth/login" class="btn btn-red">{{ $t("landing.sign_in") }}</router-link>
          <router-link to="/auth/register" class="btn btn-grey">{{ $t("landing.sign_up") }}</router-link>
        </div>

        <div class="lang-change-block text-right">
          <LanguagesButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguagesButton from "@/components/LanguagesButton";

export default {
  name: "LandingHeader",
  components: {LanguagesButton},
};
</script>
<style lang="scss">
.buttons-block{
  .btn{
    white-space: nowrap;
  }
}
.lang-change-block{
  padding-left: 20px;
  @media (max-width: 1260px){
    margin-top: 20px;
  }
  @media (max-width: 1199px){
    margin-top: 0;
  }
}
</style>