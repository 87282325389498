<template>
  <div class="wrapper inner-page">
    <div class="inner">
      <header>
        <LandingHeader></LandingHeader>
      </header>
      <div class="page-content">
        <slot/>
      </div>
    </div>

  </div>
</template>

<script>
import "@/assets/css/main.scss"
import LandingHeader from "@/components/LandingHeader"
export default {
  name: 'AuthLayout',
  components: {LandingHeader},
}
</script>